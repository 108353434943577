@import "https://fonts.googleapis.com/css2?family=VT323&display=swap";
* {
  box-sizing: border-box;
  -o-box-sizing: border-box;
  -ms-box-sizing: border-box;
  -khtml-box-sizing: border-box;
}

html {
  touch-action: pan-x pan-y;
  margin: 0;
  padding: 0;
}

body {
  color: #fff;
  margin: 0;
  padding: 12px 12px 52px;
  font-family: VT323, Roboto, Open Sans, Arial, Helvetica, sans-serif;
}
/*# sourceMappingURL=web.297446ca.css.map */
